@font-face{
  font-family: "EMprint";
  src: url("Regular.eot");
  src: url("Regular.eot?#iefix")format("embedded-opentype"),
  url("Regular.woff")format("woff"),
  url("Regular.woff2")format("woff2"),
  url("Regular.ttf")format("truetype"),
  url("Regular.svg#EMprint Regular")format("svg");
  font-weight:400;
  font-style:normal;
  font-display:swap;
}
@font-face{
  font-family: "EMprint";
  src: url("SemiBold.eot");
  src: url("SemiBold.eot?#iefix")format("embedded-opentype"),
  url("SemiBold.woff")format("woff"),
  url("SemiBold.woff2")format("woff2"),
  url("SemiBold.ttf")format("truetype"),
  url("SemiBold.svg#EMprint Semibold")format("svg");
  font-weight:600;
  font-style:normal;
  font-display:swap;
}