@import "../mixins";
@import "../variables";

.form {
  &__field {
    width: 100%;
    @include flex($flow: column, $gap: 12px);

    @include media($md) {
      gap: 9px;
    }

    label {
      @include font(18px, 600, 1.3, $text);

      @include media($md) {
        font-size: 12px;
      }
    }

    input,
    textarea,
    .form__select-button {
      width: 100%;
      padding: 14px 23px;
      @include font(16px, 400, 1, $text);
      border: 1px solid $grayLight3;
      border-radius: 25px;
      &::placeholder {
        color: $grayLight3;
      }

      @include media($md) {
        padding: 12px 25px;
        font-size: 12px;
      }
    }

    textarea {
      min-height: 218px;
      border-radius: 13px;
      resize: vertical;

      @include media($md) {
        min-height: 120px;
        border-radius: 7px;
      }
    }

    .just-validate-error-label {
      margin-top: -7px;
      font-size: 12px;

      @include media($md) {
        margin-top: -5px;
        font-size: 10px;
      }
    }
  }

  &__select {
    position: relative;
    width: 100%;

    svg {
      position: absolute;
      top: 50%;
      right: 19px;
      width: 17px;
      height: 17px;
      transform: translateY(-50%);
      color: $grayLight3;
      pointer-events: none;
      z-index: 6;
    }

    &-button {
      position: relative;
      background: $white;
      color: $grayLight3!important;
      text-align: left;
      cursor: pointer;
      z-index: 5;
    }

    &.opened {
      svg {
        transform: rotate(-180deg) translateY(50%);
      }
    }

    &.selected {
      .form__select {
        &-button {
          color: $text!important;
        }
      }
    }

    &-dropdown {
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      transform: scaleY(0);
      transform-origin: top;
      margin-top: -25px;
      padding: 24px 0 0;
      background: $white;
      @include font(16px, 400, 1, $text);
      border: 1px solid $grayLight3;
      border-radius: 0 0 25px 25px;
      transition: .3s ease;
      overflow: hidden;
      z-index: 4;

      &.opened {
        transform: scaleY(1);
      }

      li {
        label {
          width: 100%;
          padding: 8px 19px;
          display: block;
          @include font(16px, 400, 1.3, $text);
          cursor: pointer;
          transition: .3s ease;
          &:hover {
            color: $white;
            background: $blueDark;
          }
        }

        input {
          display: none;
          &:checked ~ label {
            color: $white;
            background: $blueDark;
          }
        }
      }
    }
  }

  .site-button {
    max-width: 272px;

    @include media($md) {
      width: 100%;
      max-width: 100%;
    }
  }
}