@import "../mixins";
@import "../variables";

.product {
  position: relative;
  padding: 51px 0;

  @include media($md) {
    padding: 18px 0;
  }

  &__info {
    @include grid(.5fr 1fr, start, 32px);

    @include media($md) {
      grid-template-columns: 1fr;
    }
  }

  &__meta {
    @include media($md) {
      @include flex($flow: column);
    }
  }

  &__name {
    margin-bottom: 63px;
    @include font(45px, 600, 1, $text);

    @include media($md) {
      order: 1;
      margin-bottom: 14px;
      font-size: 20px;
    }
  }

  .site-button {
    @include media($md) {
      order: 3;
    }
  }

  &__subheading {
    margin-top: 20px;
    margin-bottom: 33px;
    @include font(16px, 400, 2, $grayLight3);

    @include media($md) {
      order: 4;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  &__description {
    margin-top: 33px;
    @include font(20px, 400, 1.4, $text);

    @include media($md) {
      order: 2;
      margin-top: 0;
      margin-bottom: 33px;
      font-size: 14px;
    }
  }

  &__tabs {
    margin-bottom: 41px;
    @include flex(center, flex-start, 20px 80px, row wrap);

    @include media($md) {
      margin-bottom: 14px;
      gap: 30px;
      justify-content: space-between;
    }

    .item {
      @include font(30px, 600, 1.3, #696969);
      cursor: pointer;

      @include media($md) {
        font-size: 14px;
      }

      &.selected {
        color: $blueDark;
      }
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    * {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}