@import "../mixins";
@import "../variables";

.contacts {
  position: relative;

  &__grid {
    @include grid(repeat(2, 1fr), stretch, 33px);

    @include media($md) {
      grid-template-columns: 1fr;
      gap: 39px;
    }
  }

  &__list {
    @include flex($flow: column, $gap: 33px);

    @include media($md) {
      gap: 18px;
    }

    .item {
      position: relative;
      width: 100%;
      padding: 38px 48px;
      @include flex($flow: column);
      background: $grayLight;
      border-radius: 25px;
      cursor: pointer;

      @include media($md) {
        padding: 14px;
        border-radius: 10px;
      }

      &__heading {
        marign-bottom: 26px;
        @include font(30px, 600, 1.45, $text);
        transition: .3s ease;

        @include media($md) {
          margin-bottom: 0;
          font-size: 20px;
        }
      }

      &__arrow {
        display: none;

        @include media($md) {
          position: absolute;
          top: 21px;
          right: 28px;
          width: 19px;
          height: 19px;
          display: block;
          transition: .3s ease;

          @include svgColored($text, 100%);
        }
      }

      &__info {
        & > *:not(:last-child) {
          margin-bottom: 22px;

          @include media($md) {
            margin-bottom: 0;
          }
        }

        @include media($md) {
          max-height: 0;
          opacity: 0;
          visibility: hidden;
          transition: .3s ease;
        }
      }

      &__contact,
      &__contact a {
        @include font(20px, 400, 1.6, $text);

        @include media($md) {
          font-size: 16px;
        }

        span {
          font-weight: 600;
        }
      }

      &__icon {
        position: absolute;
        right: 38px;
        bottom: 25px;
        width: 146px;
        height: 146px;
        margin-bottom: 0!important;

        @include media($md) {
          width: 91px;
          height: 91px;
          right: 23px;
          bottom: 14px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &.selected {
        .item__heading {
          color: $blueDark;
        }
      }

      &.opened {
        .item__arrow {
          @include media($md) {
            transform: rotate(180deg);

            @include svgColored($blueDark, 100%);
          }
        }

        .item__info {
          @include media($md) {
            max-height: 1000px;
            margin-top: 20px;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  &__map {
    min-height: 466px;
    border-radius: 25px;
    overflow: hidden;

    @include media($md) {
      margin: 0 calc($containerPadding * -1) -50px;
      border-radius: 0;
    }

    & > * {
      width: 100%;
      height: 100%;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    height: 100%;
    z-index: -1;

    @include media($md) {
      display: none;
    }

    * {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}