@import "../mixins";
@import "../variables";

.about {
  position: relative;

  .container {
    position: relative;
    z-index: 1;
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 10%;
    height: 100%;
    max-height: 95%;
    z-index: 0;

    @include media($md) {
      display: none;
    }

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
    }
  }

  &__tiles {
    margin-bottom: 87px;
    @include grid(repeat(3, 1fr), start, $tilesGap);

    @include media($md) {
      margin-bottom: 38px;
      grid-template-columns: 1fr;
      gap: $tilesGapMD;
    }

    .item {
      height: 244px;
      @include flex(center, center);
      &:hover {
        box-shadow: $shadow;
      }

      @include media($md) {
        height: 137px;
      }

      img {
        max-width: 240px;
        max-height: 120px;
        object-fit: contain;

        @include media($md) {
          max-width: 125px;
        }
      }
    }
  }

  &__text {
    @include grid(repeat(2, 1fr), start, 116px);

    @include media($md) {
      grid-template-columns: 1fr;
      gap: 22px;
    }
  }
}