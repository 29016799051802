@import "../mixins";
@import "../variables";

.advantages {
  &__tiles {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: $tilesGap;
    grid-row-gap: $tilesGap;

    @include media($md) {
      @include flex($gap: $tilesGapMD, $flow: column);
    }

    .item {
      position: relative;
      padding: 30px;
      @include flex($gap: 15px, $flow: column);

      @include media($md) {
        width: 100%;
        padding: 20px 12px;
      }

      &__heading {
        max-width: 430px;
        @include font(30px, 600, 1.3, $text);

        @include media($md) {
          font-size: 20px;
        }
      }

      &__text {
        @include font(18px, 400, 1.55, $text);

        @include media($md) {
          max-width: calc(100% - 75px);
          margin-bottom: 35px;
          font-size: 14px;
        }
      }

      &__icon {
        width: 123px;
        height: 123px;
        margin-top: auto;
        margin-left: auto;

        @include media($md) {
          position: absolute;
          right: 12px;
          bottom: 20px;
          width: 55px;
          height: 55px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &__no-icon {
        .item__text {
          max-width: 100%;
          margin-bottom: 21px;
        }
      }

      &:nth-child(1) {
        grid-area: 1 / 1 / 2 / 3;

        .item__icon {
          display: none;

          @include media($md) {
            display: block;
          }
        }
      }

      &:nth-child(2) {
        grid-area: 2 / 1 / 4 / 2;
      }

      &:nth-child(3) {
        grid-area: 2 / 2 / 4 / 3;
      }

      &:nth-child(4) {
        grid-area: 1 / 3 / 3 / 4;
      }

      &:nth-child(5) {
        grid-area: 1 / 4 / 3 / 5;
      }

      &:nth-child(6) {
        grid-area: 3 / 3 / 4 / 5;

        .item__icon {
          display: none;

          @include media($md) {
            display: block;
          }
        }
      }
    }
  }
}