@import "../mixins";
@import "../variables";

.header {
  position: sticky;
  top: 0;
  padding: 32px 54px;
  @include grid(.75fr 1.4fr 2fr .9fr .3fr, center, 60px);
  background: $white;
  z-index: 5;

  @include media($md) {
    padding: 16px 25px;
    grid-template-columns: 3fr 3fr 26px;
    gap: 25px;
  }

  &__text {
    @include font(16px, 400, 1.4, $text);

    @include media($md) {
      display: none;
    }
  }

  &__search {
    position: relative;
    $inputBorderWidth: 2px;

    @include media($md) {
      height: 100%;
    }

    input {
      width: 100%;
      padding: 14px 52px 14px 37px;
      @include font(20px, 400, 1.1, $text);
      border: $inputBorderWidth solid $gray;
      border-radius: 25px;
      &::placeholder {
        color: $text;
      }

      @include media($md) {
        display: none;
      }
    }

    &-button {
      position: absolute;
      right: 22px;
      top: $inputBorderWidth;
      height: calc(100% - $inputBorderWidth * 2);
      @include flex(center, center);
      background: $white;

      @include svgColored($text, 26px);

      @include media($md) {
        pointer-events: none;
      }
    }
  }

  &__contacts {
    @include flex(flex-end, center, 10px, column);

    .item {
      @include font(20px, 600, 1.75, $text);
      &:hover {
        color: $blue;
      }
    }

    @include media($md) {
      display: none;
    }
  }

  &__menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 40vw;
    transform: translateX(100%);
    min-width: 400px;
    max-width: 898px;
    height: 100vh;
    padding: 90px 100px;
    @include flex($gap: 50px, $flow: column);
    background: $purple;
    opacity: 0;
    visibility: hidden;
    z-index: 15;
    transition: .3s ease;

    @include media($md) {
      padding: 48px 28px;
      gap: 25px;
    }

    &_opened {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }

    &-close {
      position: absolute;
      top: 42px;
      right: 42px;
      cursor: pointer;

      @include svgColored($white, 38px);

      @include media($md) {
        top: 30px;
        right: 35px;

        @include svgColored($white, 15px);
      }
    }

    .item {
      @include font(45px, 600, 1, $white);
      &:hover {
        color: $orange;
      }

      @include media($md) {
        font-size: 25px;
      }
    }

    &-toggler {
      @include flex(center, center);
      cursor: pointer;

      @include svgColored($orange, 56px);

      @include media($md) {
        padding: 10px;
        margin: -10px;

        @include svgColored($orange, 26px);
      }
    }
  }
}