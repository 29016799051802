@import "../mixins";
@import "../variables";

* {
  font-family: 'EMprint', sans-serif!important;
  font-variant-numeric: lining-nums proportional-nums;
  box-sizing: border-box;
}

html {
  background: $white;
  scroll-behavior: smooth;
}

body {

}

a {
  text-decoration: none;
  transition: .3s ease;
}

img,
svg {
  width: 100%;
  max-width: 100%;
  font-size: 0;
  line-height: 0;
  transition: .3s ease;
}

.container {
  position: relative;
  max-width: 1460px;
  margin: 0 auto;
  padding: 0 $containerPadding;
  z-index: 1;
}

.app {
  position: relative;
  max-width: 2000px;
  margin: 0 auto;
}

.site {
  &-mask {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(8, 23, 41, 0.63);
    backdrop-filter: blur(4.5px);
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    transition: .3s ease;

    &_opened {
      opacity: 1;
      visibility: visible;
    }
  }

  &-button {
    width: 100%;
    max-width: 332px;
    padding: 24px 12px;
    background: $purple;
    @include font(18px, 600, 1.2, $white, center);
    border-radius: 35px;
    cursor: pointer;
    transition: .3s ease;
    &:hover {
      background: $purpleLight;
    }

    @include media($md) {
      max-width: 156px;
      padding: 8px 12px;
      font-size: 12px;
    }

    &_blue {
      padding: 18px 12px;
      background: $blueDark;
      &:hover {
        background: $blueMedium;
      }

      @include media($md) {
        padding: 16px 12px;
      }
    }
  }

  &-block {
    padding: 90px 0;

    @include media($md) {
      padding: 50px 0;
    }

    &-heading {
      position: relative;
      margin: 0 0 -90px;
      padding: 90px 0 0;
      z-index: 2;

      @include media($md) {
        margin: 0 0 -50px;
        padding: 50px 0 0;
      }

      & + footer {
        margin-top: 180px;
      }
    }

    &_gray {
      background: $grayLight;
    }

    &__heading {
      margin-bottom: 46px;
      @include font(40px, 600, 1.275, $text);

      @include media($md) {
        margin-bottom: 36px;
        font-size: 25px;
      }
    }

    &__text {
      width: 80%;
      max-width: 983px;

      @include media($md) {
        width: 100%;
      }

      &_two-column {
        width: 100%;
        max-width: 100%;
        columns: 2;
        gap: 115px;

        @include media($md) {
          columns: 1;
        }
      }
    }
  }

  &-tile {
    background: $grayLight;
    border-radius: $tilesRadius;
  }

  &-heading {
    margin-bottom: 60px;
    @include font(50px, 600, 1, $text);

    @include media($md) {
      margin-bottom: 25px;
      font-size: 25px;
    }
  }

  &-tabs {
    &__list {
      margin: 0 48px 20px;
      @include flex(flex-end, flex-start, 63px, row wrap);

      @include media($md) {
        margin: 0 12px 10px;
        gap: 36px;
      }

      .item {
        position: relative;
        @include font(24px, 400, 1.46, $text);
        cursor: pointer;
        transition: .3s ease;
        &::after {
          content: '';
          position: absolute;
          top: calc(100% + 5px);
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% + 20px);
          height: 3px;
          background: transparent;
          border-radius: 10px;
          transition: .3s ease;

          @include media($md) {
            top: calc(100% + 1px);
            width: 100%;
            height: 1px;
          }
        }

        @include media($md) {
          font-size: 14px;
        }

        &.selected {
          font-weight: 600;
          &::after {
            background: $black;
          }
        }
      }
    }

    &__area {
      position: relative;
    }

    &__item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: .3s ease;

      &.selected {
        position: static;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &-slider {
    $arrowSize: 39px;
    position: relative;
    overflow: visible!important;

    @include media($md) {
      margin-bottom: 40px;
    }

    &__arrows {
      position: absolute;
      top: calc(($arrowSize + 37px) * -1);
      right: 0;
      @include flex(center, flex-end, 41px, row nowrap);

      @include media($md) {
        top: calc(100% + 20px);
        right: 13px;
        gap: 26px;
      }
    }

    &__arrow {
      cursor: pointer;

      @include svgColored($text, $arrowSize);

      @include media($md) {
        @include svgColored($text, 21px);
      }

      &-disabled {
        cursor: default;

        @include svgColored($grayLight2, $arrowSize);

        @include media($md) {
          @include svgColored($grayLight, 21px);
        }
      }
    }

    &__pagination {
      position: absolute;
      left: 61px;
      bottom: 38px;
      @include flex(center, $gap: 22px);
      z-index: 2;

      @include media($md) {
        left: 17px;
        bottom: 15px;
        gap: 8px;
      }

      .item {
        width: 16px;
        height: 16px;
        background: $white;
        border-radius: 50%;
        opacity: .47;
        cursor: pointer;
        transition: .3s ease;

        @include media($md) {
          width: 6px;
          height: 6px;
        }

        &:hover,
        &__active {
          opacity: 1;
        }
      }
    }

    .swiper {
      &-slide {
        border-radius: 27px;
        overflow: hidden;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.content {
  & > * {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  h1, h2, h3 {
    padding-bottom: 20px;
    @include font(45px, 600, 1.1, $text);
  }

  p {
    @include font(24px, 400, 1.46, $text);

    @include media($md) {
      font-size: 14px;
    }
  }

  a {
    color: $blueDark;
    text-decoration: underline;
  }

  b,
  strong {
    font-weight: 600;
  }

  ul,
  ol {
    li {
      position: relative;
      padding-left: 33px;
      @include font(24px, 400, 1.46, $text);

      @include media($md) {
        font-size: 14px;
      }

      &::before {
        content: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_136_1630)'%3E%3Cpath d='M6.19431 0.093273C6.12781 0.010523 6.01606 -0.021227 5.91656 0.014523C5.83706 0.042523 5.77831 0.108273 5.75781 0.187273C5.70606 0.285273 5.45156 0.631773 5.18256 0.998023C3.94206 2.68727 1.63281 5.83177 1.63281 7.63252C1.63281 10.0408 3.59181 12 5.99956 12C8.40781 12 10.3668 10.0408 10.3668 7.63252C10.3668 5.29752 6.36481 0.304523 6.19431 0.093273Z' fill='%233E60C1'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_136_1630'%3E%3Crect width='12' height='12' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        position: absolute;
        top: 0;
        left: 0;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}