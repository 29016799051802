@import "../mixins";
@import "../variables";

.actions {
  &__list {
    max-width: 983px;

    .item {
      @include grid(0.3fr 1fr, start, 32px);
      &:not(:last-child) {
        margin-bottom: 55px;
      }

      @include media($md) {
        grid-template-columns: 1fr;
        gap: 12px;
      }

      &__image {
        position: relative;
        padding-top: 100%;
        border-radius: 11px;
        overflow: hidden;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__info {
        height: 100%;
        @include flex($gap: 17px, $flow: column);

        @include media($md) {
          gap: 7px;
        }
      }

      &__heading {
        @include font(24px, 600, 1.45, $text);

        @include media($md) {
          font-size: 18px;
        }
      }

      &__description {
        @include font(20px, 400, 1.45, $text);

        @include media($md) {
          font-size: 16px;
        }
      }

      &__footer {
        margin-top: auto;
        @include flex(center, $gap: 45px);

        @include media($md) {
          width: 100%;
          margin-top: 16px;
          gap: 10px;
          justify-content: space-between;
        }
      }

      &__more {
        @include flex(center, $gap: 9px);
        @include font(22px, 400, 1.45, $text);

        @include svgColored($text, 22px);

        @include media($md) {
          font-size: 16px;

          @include svgColored($text, 14px);
        }
      }

      &__date {
        @include font(18px, 400, 1.45, $grayLight3);

        @include media($md) {
          font-size: 16px;
        }
      }
    }
  }
}