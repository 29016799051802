@import "../mixins";
@import "../variables";

.catalog {
  position: relative;
  overflow: hidden;
  $gap: 32px;
  $gapMD: 24px;

  & > * {
    position: relative;
    z-index: 2;
  }

  &-categories {
    @include grid(repeat(3, 1fr), stretch, $gap);

    @include media($md) {
      grid-template-columns: 1fr;
      gap: $gapMD;
    }
  }

  &-subcategories {
    position: relative;

    .catalog__item {
      width: calc(33% - $gap);
      margin-bottom: $gap;

      @include media($md) {
        width: calc(100% - $containerPadding * 2);
        margin-bottom: $gapMD;
      }
    }
  }

  &-products {
    @include grid(repeat(4, 1fr), stretch, $gap);

    @include media($md) {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }
  }

  .catalog__item {
    padding: 45px;
    background: $white;
    border-radius: 25px;

    @include media($md) {
      padding: 30px 26px;
    }

    &_gray {
      background: $grayLight;
    }

    &_product {
      padding: 25px 54px 22px;
      @include flex(center, flex-start, 0, column);

      @include media($md) {
        padding: 6px 19px 10px;
      }

      &:hover {
        & > * {
          color: $blue!important;
        }
      }
    }

    .item {
      &__list {
        @include flex($gap: 23px, $flow: column);

        @include media($md) {
          gap: 19px;
        }

        a {
          @include font(20px, 400, 1.25, $black);
          &:hover {
            color: $blue;
          }

          @include media($md) {
            font-size: 16px;
          }
        }
      }

      &__icon {
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &__heading {
        @include font(30px, 600, 1.3, $text);
      }

      &__image {
        margin-bottom: 40px;

        @include media($md) {
          margin-bottom: 11px;
        }
      }

      &__name {
        margin-bottom: 36px;
        @include font(20px, 600, 1.25, $text, center);
        transition: .3s ease;

        @include media($md) {
          margin-bottom: 17px;
          font-size: 12px;
        }
      }

      &__more {
        @include flex(center, $gap: 9px);
        @include font(22px, 400, 1.5, $blue);

        @include svgColored($blue, 22px, 22px);

        @include media($md) {
          font-size: 12px;

          @include svgColored($blue, 11px, 11px);
        }
      }
    }

    &_category {
      @include flex($gap: 30px, $flow: column);

      .item__icon {
        img {
          max-width: 120px;
          height: 70px;

          @include media($md) {
            max-width: 100px;
            height: auto;
            max-height: 60px;
          }
        }
      }
    }
  }

  &__text {
    margin: 110px 0;

    @include media($md) {
      margin: 41px 0;
    }
  }

  &__categories-list {
    margin: 53px 0;
    @include flex(center, flex-start, 30px, row wrap);

    @include media($md) {
      margin: 24px 0;
      gap: 11px;
    }

    .item {
      padding: 15px 37px;
      background: $white;
      @include font(20px, 400, 1.15, $text, center);
      border-radius: 25px;
      transition: .3s ease;

      @include media($md) {
        padding: 5px 16px;
        font-size: 12px;
      }

      &.current,
      &:hover {
        background: $blue;
        color: $white;
      }
    }
  }

  &__bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;

    @include media($md) {
      display: none;
    }
  }
}

.category {
  position: relative;
  min-height: 375px;

  &__image {
    max-width: 480px;
    max-height: 200px;
    margin-bottom: 60px;

    @include media($md) {
      max-width: 171px;
      max-height: 51px;
      margin-bottom: 25px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__description {
    max-width: 600px;

    @include media($md) {
      max-height: 285px;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    * {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}