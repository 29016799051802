$black: #000;
$white: #fff;

$text: #081729;
$blue: #3666D6;
$blueDark: #3E60C1;
$blueLight: #4EBAFF;
$blueLightDark: #0E8DDE;
$blueMedium: #5C83F4;
$gray: #E1DCE2;
$grayLight: #EDEEF2;
$grayLight2: #D8D9E0;
$grayLight3: #BBBFD1;
$orange: #F56132;
$purple: #312086;
$purpleLight: #6956C6;

$shadow:  -4px 15px 200px 0px rgba(54, 102, 214, 0.50);

$containerPadding: 15px;
$tilesRadius: 27px;
$tilesGap: 32px;
$tilesGapMD: 12px;

$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1199px;
$xxl: 1399px;
$xxxl: 1699px;
$w1500: 1499px;