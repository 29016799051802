@import "../mixins";
@import "../variables";

.news {
  position: relative;

  .container {
    position: relative;
    z-index: 1;
  }

  &__list {
    @include grid(repeat(3, 1fr), start, $tilesGap);

    @include media($md) {
      grid-template-columns: 1fr;
      gap: 19px;
    }

    .item {
      position: relative;
      padding: 33px 33px 23px;
      @include flex($gap: 65px, $flow: column);
      background: $white;
      border-radius: $tilesRadius;

      @include media($md) {
        padding: 22px 14px;
      }

      &__heading {
        @include font(22px, 600, 1.45, $text);
        transition: .3s ease;

        @include media($md) {
          font-size: 16px;
        }
      }

      &__date {
        margin-top: auto;
        @include font(18px, 600, 1.45, $gray);

        @include media($md) {
          font-size: 12px;
        }
      }

      &__icon {
        position: absolute;
        right: 33px;
        bottom: 23px;

        @include svgColored($blue, 50px);

        @include media($md) {
          right: 14px;
          bottom: 12px;

          @include svgColored($blue, 28px);
        }
      }

      &:hover {
        box-shadow: $shadow;

        .item__heading {
          color: $blue;
        }
      }
    }
  }

  &__link {
    margin-top: 63px;
    @include flex(center, flex-start, 10px, row nowrap);
    @include font(22px, 600, 1, $text);

    @include svgColored($text, 22px);

    @include media($md) {
      margin-top: 38px;
      gap: 6px;
      font-size: 16px;

      @include svgColored($text, 14px);
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 0;

    @include media($md) {
      display: none;
    }

    img {
      height: 100%;
    }
  }
}