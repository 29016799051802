@import "../mixins";
@import "../variables";

.footer {
  position: relative;
  background: linear-gradient(180deg, #312086 0%, #081729 100%);

  & > * {
    position: relative;
    z-index: 1;
  }

  &__logo {
    max-width: 200px;
    display: block;

    @include media($md) {
      max-width: 120px;
    }
  }

  &__top {
    padding: 33px 0;
    background: rgba(9, 24, 43, 0.4);

    @include media($md) {
      padding: 22px 0;
    }
  }

  &__bottom {
    padding: 48px 0 150px;
    @include grid(1.2fr 1.2fr 1fr 1fr, start, 50px);

    @include media($md) {
      padding: 31px 0 50px;
      grid-template-columns: 1fr;
      gap: 25px;
    }
  }

  &__menu {
    @include flex($gap: 8px, $flow: column);

    @include media($md) {
      gap: 25px;
    }

    &-block {
      @include flex($gap: 24px, $flow: column);

      .item {
        display: inline-block!important;
      }
    }

    .item {
      @include font(16px, 600, 1.5, $white);
      opacity: .5;

      @include media($md) {
        display: none;
      }

      &__heading {
        font-size: 20px;
        line-height: 2;
        opacity: 1;

        @include media($md) {
          display: inline-block;
        }
      }
    }
  }

  &__credits {
    padding-bottom: 30px;
    @include grid(3.4fr 1fr, center, 10px);

    @include media($md) {
      grid-template-columns: 1fr;
      gap: 38px;
    }

    &-menu {
      @include flex($gap: 48px);

      @include media($md) {
        order: 1;
        flex-flow: column;
        gap: 10px;
      }

      .item {
        @include font(16px, 600, 1.5, $white);
        opacity: .5;
        &:first-child {
          opacity: .3;
        }

        @include media($md) {
          font-size: 12px;
        }
      }
    }

    &-copy {
      width: 100%;
      height: 100%;
      max-width: 185px;
      max-height: 31px;

      @include media($md) {
        order: 0;
      }

      @include svgColored(#4B5461);
    }
  }

  &__bg {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;

    @include media($md) {
      height: 60%;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}