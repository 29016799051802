@import "../mixins";
@import "../variables";

.hero {
  position: relative;
  height: 100vh;
  max-height: 905px;
  padding: 130px 0 50px;
  overflow: hidden;

  @include media($md) {
    height: unset;
    min-height: unset;
    padding: 33px 0 134px;

    .container {
      position: static;
    }

    .site-button {
      position: absolute;
      left: $containerPadding;
      bottom: 43px;
      margin: 0;
    }
  }

  &__content {
    @include grid(.8fr 1fr, start, 120px);

    @include media($md) {
      grid-template-columns: 1fr;
      gap: 40px;
    }

    .item {
      &__heading {
        width: 1px;
        margin-bottom: 72px;
        @include font(78px, 600, 1.07, $text);

        @include media($md) {
          font-size: 30px;
        }
      }

      &__list {
        width: 100%;
        margin-bottom: 48px;
        @include flex(center, space-between, 10px, row nowrap);

        @include media($md) {
          margin-bottom: 25px;
        }

        img {
          width: auto;
          max-height: 80px;
          object-fit: contain;

          @include media($md) {
            max-height: 35px;
          }
        }
      }

      &__text {
        @include font(21px, 400, 1.4, $text);

        @include media($md) {
          font-size: 14px;
        }
      }
    }
  }

  &__bg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      height: 100%;
      object-fit: cover;
    }
  }
}

.after-hero {
  padding: 90px 0 115px;
  background: $blue url('@images/backgrounds/after-hero.png') center right no-repeat;

  @include media($md) {
    padding: 39px 0 450px;
    background-position-x: 75%;
    background-position-y: 150%;
    background-size: 420%;
  }

  &__heading {
    max-width: 778px;
    margin-bottom: 40px;
    @include font(78px, 600, 1.07, $white);

    @include media($md) {
      margin-bottom: 24px;
      font-size: 30px;
    }
  }

  &__text {
    max-width: 626px;
    margin-bottom: 84px;
    @include font(24px, 400, 1.45, $white);

    @include media($md) {
      margin-bottom: 39px;
      font-size: 14px;
    }
  }
}