@import "../mixins";
@import "../variables";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background: rgba(8, 23, 41, 0.63);
  backdrop-filter: blur(4.5px);
  opacity: 0;
  visibility: hidden;
  transition: .3s ease;
  z-index: 100;

  &_visible {
    opacity: 1;
    visibility: visible;
  }

  &__container {
    position: relative;
    margin: 60px auto;
    width: 100%;
    max-height: calc(100vh - 60px);
    max-width: 960px;

    @include media($md) {
      margin: 0 auto;
      max-height: 100vh;
    }
  }

  &__content {
    padding: 31px 45px;
    background: $white;
    border-radius: 8px;

    @include media($md) {
      padding: 31px 24px 39px;
    }
  }

  &__close {
    position: absolute;
    top: 29px;
    right: 26px;
    width: 19px;
    height: 19px;
    cursor: pointer;

    @include media($md) {
      top: 19px;
      right: 29px;
      width: 8px;
      height: 8px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__header {
    margin: 0 -45px;
    padding: 0 45px 25px;
    border-bottom: 1px solid $grayLight3;

    @include media($md) {
      margin: 0 -24px;
      padding: 0 24px 21px;
    }

    &-heading {
      @include font(30px, 700, 1.3, $text);

      @include media($md) {
        font-size: 20px;
      }
    }
  }

  &__text {
    margin: 30px 0;
    @include font(18px, 400, 1.3, $text);

    @include media($md) {
      margin: 21px 0 28px;
    }
  }

  &__row {
    $gap: 45px;
    @include flex($flow: row wrap, $gap: $gap);

    @include media($md) {
      gap: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 34px;

      @include media($md) {
        margin-bottom: 19px;
      }
    }

    .col {
      &-33 {
        flex: 0 0 33%;
        margin: calc($gap / -2);
        padding: calc($gap / 2);

        @include media($md) {
          flex: 0 0 100%;
          margin: 0;
          padding: 0;
        }
      }

      &-45 {
        flex: 0 0 45%;
        margin: calc($gap / -2);
        padding: calc($gap / 2);

        @include media($md) {
          flex: 0 0 100%;
          margin: 0;
          padding: 0;
        }
      }

      &-100 {
        flex: 0 0 100%;
        margin: calc($gap / -2);
        padding: calc($gap / 2);

        @include media($md) {
          flex: 0 0 100%;
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  &__caption {
    @include font(14px, 14, 1.3, $grayLight3);

    @include media($md) {
      font-size: 10px;
      text-align: center;
    }

    a {
      color: $grayLight3;
      text-decoration: underline;
    }
  }
}