@import "../mixins";
@import "../variables";

.breadcrumbs {
  @include flex(center, flex-start, 21px, row nowrap);

  .item {
    position: relative;
    @include flex(center, flex-start, 5px, row nowrap);
    @include font(18px, 400, 1.2, $black);
    &:not(:last-child) {
      &::after {
        content: '/';
        position: absolute;
        right: -18px;
        top: 60%;
        transform: translate(-50%,-50%);
        pointer-events: none;
      }
    }

    @include svgColored($purple, 18px);

    @include media($w1500) {
      gap: 18px;
      font-size: 16px;

      &:not(:last-child) {
        &::after {
          right: -14px;
        }
      }

      @include svgColored($purple, 16px);
    }

    @include media($md) {
      display: none;
    }
  }

  &-container {
    margin: 22px 0;

    & + .site-block {
      padding-top: 22px;
    }
  }
}