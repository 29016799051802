@import "../mixins";
@import "../variables";

.points {
  &__text {
    margin-bottom: 67px;

    @include media($md) {
      margin-bottom: 24px;
    }
  }

  &__filter {
    margin-bottom: 67px;
    @include flex(center, space-between, 15px, row wrap);

    @include media($md) {
      margin-bottom: 30px;
    }

    &-city {
      flex: 0 1 364px;

      @include media($md) {
        flex: 1 0 100%;
      }
    }

    &-buttons {
      flex: 0 1;
      @include flex(center, flex-start, 38px, row nowrap);

      @include media($md) {
        flex: 1 0 100%;
        gap: 16px;
      }

      .item {
        min-width: 260px;
        padding: 12px 36px;
        @include flex(center, flex-start, 32px, row nowrap);
        @include font(20px, 600, 1.2, $grayLight3);
        white-space: nowrap;
        border: 2px solid $grayLight3;
        border-radius: 41px;
        cursor: pointer;
        transition: .3s ease;

        @include svgColored($grayLight3, 35px);

        &.selected {
          background: $blueDark;
          color: $white;
          border-color: $blueDark;

          @include svgColored($white, 35px);
        }

        @include media($md) {
          min-width: unset;
          flex: 0 0 calc(50% - 8px);
          gap: 15px;
          font-size: 14px;

          svg {
            width: 19px!important;
            height: 19px!important;
          }
        }
      }
    }
  }

  &__list {
    border-radius: 7px;

    @include media($md) {
      border-radius: 0;
      @include flex($flow: column, $gap: 24px);
    }

    &-row {
      @include grid(1fr 1.2fr 1fr 1.7fr, stretch, 0);
      border-bottom: 2px solid $grayLight;

      @include media($md) {
        width: 100%;
        padding: 16px;
        @include flex($flow: column, $gap: 14px);
        background: $grayLight;
        border-radius: 17px;
      }

      .cell {
        padding: 30px 26px;
        @include flex(center);
        @include font(20px, 400, 1.2, $text);
        border-right: 2px solid $grayLight;

        @include media($md) {
          padding: 0;
          display: block;
          font-size: 14px;
          border: unset;
        }

        &:first-child {
          border-left: 2px solid $grayLight;

          @include media($md) {
            @include font(16px, 600, 1.2, $blueDark);
            border: unset;
          }
        }
      }
    }

    &-headings {
      @include media($md) {
        display: none;
      }

      .cell {
        padding: 15px 26px;
        background: $grayLight;
        @include font(23px, 600, 1.2, $blueDark);
      }
    }
  }

  &__map {
    width: 100%;
    min-height: 80vh;
    border-radius: 27px;
    overflow: hidden;

    @include media($md) {
      width: calc(100% + ($containerPadding * 2));
      min-height: 466px;
      margin: 0 calc($containerPadding * -1) -50px;
      border-radius: 0;
    }

    #map {
      width: 100%!important;
      height: 100%;
      min-height: 80vh;
    }
  }
}